declare const window: any;
import { getCookie, setCookie } from '@/utils/cookie'
import { generateUUID } from './utils';

// 上报数据
function uploadLog(requestData: any) {
  let currentDomain = window.location.hostname;
  if(currentDomain != 'www.crazysales.com.au') {
    return
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  };

  return fetch("https://www.crazysales.com.au/fp/log", requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error: any) => {
      console.log(error);
    });
}

// 上报回第一次数据给后端并设置
function uploadFirstData() {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    // body: JSON.stringify(),
  };

  return fetch(`/tracking/dataAnalysis?URL=${window.location.href}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.log(error);

    });
}

// 推送matomoID
function sendMatomoId(matomoId: any) {
  if (window._paq) {
    window._paq.push(['setVisitorId', matomoId]);
    window._paq.push(['trackPageView']);
  } else {
    setTimeout(() => {
      sendMatomoId(matomoId)
    }, 200);
  }
}


const allocateBucket = (conf: any, bucketId: number) => {
  let keys = Object.keys(conf);
  let oldKey = undefined;
  let begin = 0;
  let end = 0;
  for (let idx in keys) {
    let key = keys[idx];
    if (!oldKey) {
      end = conf[key];
    } else {
      begin += conf[oldKey];
      end += conf[key];
    }

    if (bucketId >= begin && bucketId < end) {
      return key;
    }

    oldKey = key;
  }
}

const hash = (uid: string, salt: number) => {
  let h1 = 0xdeadbeef ^ salt, h2 = 0x41c6ce57 ^ salt;
  for (let i = 0, ch; i < uid.length; i++) {
    ch = uid.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

const calculate = (visitorId: string, response: any, isFirst: boolean) => {
  const { experimentName, group, expiresTime } = response
  let conf: any = {};
  for (let idx in group) {
    conf[idx] = group[idx].ratio
  }
  const salt = Object.keys(conf).length;
  const h = hash(visitorId, salt);
  const bucketId = h % 100;
  const bucket = allocateBucket(conf, bucketId);

  const expiresTimeNum = new Date(expiresTime).getTime()
  const nowTimeNum = new Date().getTime()

  // 判断ABtest是否在进行
  if (expiresTimeNum > nowTimeNum && !!bucket) {
    const hour = 0.5
    const nextRequestTime = nowTimeNum + 60 * 60 * 1000 * hour
    // 判断下次请求是否大于过期时间，是则直接使用过期时间
    if (nextRequestTime > expiresTimeNum) {
      settingCookie(expiresTimeNum, experimentName, bucket)
    } else {
      settingCookie(nextRequestTime, experimentName, bucket)
    }
  } else {
    // 失效或者未算出
    settingCookie(new Date().getTime() + 60 * 60 * 1000 * 0.5, experimentName, "A")

    const errorData = JSON.stringify({ expiresTimeNum, nowTimeNum, bucket })
    // 日志上传
    const params = {
      conf: errorData,
      bucketId: "calculate-error",
      experimentName: experimentName
    }
    uploadLog(params)
  }

  if (isFirst) {
    uploadFirstData()
  }
  // var unique_id = getCookie("unique_id")
  // 日志上传
  // const params = {
  //   conf: conf || 'null',
  //   visitorId: visitorId || 'null',
  //   bucketId: bucketId || 'null',
  //   bucket: bucket || 'null',
  //   uniqueId: unique_id || 'null',
  //   experimentName: experimentName || 'null'
  // }
  // uploadLog(params)

  return bucket
}

// type : api|mixture|direct ,   api方式|混合模式（部分页面API｜部分后端渲染｜后端渲染）
export const abTestCalculate = (name: string) => {
  const { _bfuid, isFirst } = settingBfuid()

  const abtestPromise = new Promise((resolve, reject) => {
    const nextRequestTime = getCookie("nextRequestTime")
    const serviceGroup = getCookie("X-Service-Group-AB")

    if (nextRequestTime && serviceGroup) {
      // console.log("过期时间没到、使用算出来的缓存");
      // console.log(serviceGroup);


      resolve(serviceGroup)
      return
    } else {
      if(!name) return
      getGroup(name, _bfuid, isFirst, resolve)
    }
  })
  abtestPromise.catch((error: any) => {
    settingCookie(new Date().getTime() + 60 * 60 * 1000 * 0.5, name, "A")
    // 日志上传
    const params = {
      conf: error.message || null,
      bucketId: "promise-error",
      experimentName: name
    }
    uploadLog(params)
  })
  return abtestPromise
}

function settingBfuid() {
  let _bfuid = getCookie("_bfuid")
  let experimentName = getCookie("X-AB-Experiment-Name")
  let group = getCookie("X-Service-Group-AB")
  const expires = new Date(new Date().getTime() + (0.5 * 60 * 60 * 1000))

  if (experimentName) {
    setCookie("X-AB-Experiment-Name", experimentName, { expires })
  }

  if (group) {
    setCookie("X-Service-Group-AB", group, { expires })
  }

  if (_bfuid) {
    // 默认过期搞个半小时
    setCookie("_bfuid", _bfuid, { expires });
    sendMatomoId(_bfuid)
  } else {
    _bfuid = generateUUID()
    setCookie("_bfuid", _bfuid, { expires });
    sendMatomoId(_bfuid)
    // 第一次直接return
    return { _bfuid, isFirst: true }
  }

  return { _bfuid, isFirst: false }
}

function settingCookie(expiresTimeNum: number, experimentName: string, bucket: string) {
  setCookie("nextRequestTime", expiresTimeNum, { expires: new Date(expiresTimeNum) })
  setCookie("X-AB-Experiment-Name", experimentName, { expires: new Date(expiresTimeNum) });

  // 获取两个日期
  let paritySwapGroup = bucket
  const startDate: any = new Date('2024-07-22');
  const endDate: any = new Date();

  // 计算时间差（毫秒）
  const timeDifference: any = endDate - startDate;

  // 将时间差转换为天数
  const daysDifference = parseInt(`${timeDifference / (1000 * 60 * 60 * 24)}`);

  if (daysDifference % 2 === 0) {
    paritySwapGroup = bucket === "A" ? "B" : bucket === "B" ? "A" : bucket;
  }
  setCookie("X-Service-Group-AB", paritySwapGroup, { expires: new Date(expiresTimeNum) });
}

function easyUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function errorFunction(resolve: any, response: any) {
  // // console.log("请求AB、指纹ID不正常，使用unique_id");
  // var unique_id = getCookie("unique_id") || ""
  // 使用简单的easyuuid
  const serviceGroup = calculate(easyUUID(), response, true)
  // console.log(serviceGroup);
  resolve(serviceGroup)
}


const getGroup = async (name: string, _bfuid: string, isFirst: boolean, resolve: any) => {
  let defaultGroup = "A"
  try {
    const data = await fetch(`/others/getABTestConfig.php?name=${name}`, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': '*/*'
      },
      mode: 'cors'
    })
    if (!data.ok) {
      throw new Error('请求出错：' + data.statusText);
    }

    const response: any = await data.json()

    if (response.default) {
      defaultGroup = response.default
    }

    if (typeof window !== 'undefined') {
      if (_bfuid) {
        const serviceGroup = calculate(_bfuid, response, isFirst)
        if (serviceGroup) {
          // console.log("请求AB、指纹ID正常");
          // console.log(serviceGroup);
          resolve(serviceGroup)
        } else {
          // console.log(`请求AB、指纹ID计算有误，使用默认兜底${defaultGroup}`);
          resolve(defaultGroup)
        }
      } else {
        errorFunction(resolve, response)
      }

    } else {
      // console.log(`不在windows环境，使用默认兜底${defaultGroup}`);
      settingCookie(new Date().getTime() + 60 * 60 * 1000 * 0.5, name, defaultGroup)
      // 日志上传
      const params = {
        conf: JSON.stringify(response) || null,
        visitorId: "window-error",
        experimentName: name
      }
      uploadLog(params)
      resolve(defaultGroup)
    }

  } catch (error: any) {
    // console.log(`接口挂壁、兜底去${defaultGroup}`);
    settingCookie(new Date().getTime() + 60 * 60 * 1000 * 0.5, name, defaultGroup)
    // 日志上传
    const params = {
      conf: error.message || null,
      bucketId: "getABTestConfig-error",
      experimentName: name
    }
    uploadLog(params)
    resolve(defaultGroup)
  }
}